import { useState } from 'react'
import { RENDERER_URL } from '../../../../constants'
import { cnWizardVerticalMenu } from './Wizard-VerticalMenu'
import { useTranslation } from 'react-i18next'
import { useEffectDidMount } from '../../../../hooks/useEffectDidMount'
import { Button, cnButton } from '../../../components/Button'
import { BUTTON_MODE_LIGHT_GREEN } from '../../../constants'

const WizardModalDownload = ({ files }: { files: string[] }) => {
    const { t } = useTranslation()
    const [isLoading, setIsLoading] = useState(false)
    const [isUploading, setIsUploading] = useState(false)
    const [stateFiles, setStateFiles] = useState(
        files?.map((file) => ({
            fileUrl: `${RENDERER_URL}${file}`,
            exists: false,
        })) || []
    )

    const saveImage = (fileUrl: string) => {
        const link = document.createElement('a')
        link.href = fileUrl
        link.download = `image-${Date.now()}.png`
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    }

    const checkFileExists = async (url: string) => {
        try {
            const response = await fetch(url, { method: 'HEAD' })
            return response.ok
        } catch (error) {
            console.log(`❌ Файл ${url} не доступен`)
            return false
        }
    }
    const checkFilesSequentially = async (urls: string[]) => {
        for (const url of urls) {
            const timeouts = Array.from({ length: 15 }, (_, i) => 500 + i * 200)

            let found = false
            for (let delay of timeouts) {
                await new Promise((resolve) => setTimeout(resolve, delay))

                const exists = await checkFileExists(url)

                if (exists) {
                    console.log(
                        `✅ Проверка через ${delay}ms. Файл ${url} существует`
                    )
                    setStateFiles((prev) =>
                        prev.map((f) =>
                            f.fileUrl === url ? { ...f, exists } : f
                        )
                    )
                    found = true
                    break
                }
            }

            if (!found) {
                console.warn(`❌ Файл ${url} не найден после всех попыток`)
            }
        }
    }
    useEffectDidMount(() => {
        checkFilesSequentially(stateFiles.map((file) => file.fileUrl))
    })

    const handleDownload = async (
        url: string,
        index: number,
        lastIndex: number
    ) => {
        if (index === 0) {
            setIsLoading(true)
        }
        if (!url) {
            console.log('❌ Файл недоступен.')
            return
        }

        const response = await fetch(url)
        if (!response.ok) throw new Error('❌ Ошибка загрузки файла')

        const blob = await response.blob()
        const objectUrl = URL.createObjectURL(blob)
        saveImage(objectUrl)
        if (index === lastIndex) {
            setIsLoading(false)
            setIsUploading(true)
        }
        return true
    }

    const handleDownloadFiles = (arrPaths: string[]) => {
        arrPaths.forEach((path, index) => {
            handleDownload(path, index, arrPaths.length - 1)
        })
    }

    return (
        <div className={cnWizardVerticalMenu('Modal')}>
            {files && files.length > 0 ? (
                <>
                    <div className={cnWizardVerticalMenu('ModalTitle')}>
                        {t('Рендер 3D модели для скачивания')}
                    </div>
                    <div className={cnWizardVerticalMenu('ModalDescription')}>
                        <p className={cnWizardVerticalMenu('ModalText')}>
                            {t(
                                `После завершения процесса создания изображений кухни, они будут доступны для скачивания в вашем браузере. 
                        Время ожидания зависит от размера изображений и скорости вашего интернета и может занять несколько минут, но как правило, это не более 1 мин. 
                        Если изображение не скачается, пожалуйста, повторите попытку позже.`
                            )}
                        </p>
                    </div>
                    <div className={cnWizardVerticalMenu('ModalImages')}>
                        {stateFiles.length > 0 &&
                            stateFiles.map((file, index) => (
                                <div
                                    key={index}
                                    className={cnWizardVerticalMenu(
                                        'ModalImage'
                                    )}
                                >
                                    {file.exists ? (
                                        <img
                                            className={cnWizardVerticalMenu(
                                                'Image'
                                            )}
                                            src={file.fileUrl}
                                            alt={'image' + index}
                                        />
                                    ) : (
                                        <div
                                            className={cnWizardVerticalMenu(
                                                'Loader'
                                            )}
                                        ></div>
                                    )}
                                </div>
                            ))}
                    </div>
                    {stateFiles.every((file) => file.exists) &&
                        !isUploading && (
                            <>
                                {isLoading ? (
                                    <>
                                        <div
                                            className={cnWizardVerticalMenu(
                                                'ButtonLoader'
                                            )}
                                        >
                                            <div
                                                className={cnWizardVerticalMenu(
                                                    'Loader'
                                                )}
                                            ></div>
                                        </div>
                                        <div
                                            className={cnWizardVerticalMenu(
                                                'ModalDescription'
                                            )}
                                        >
                                            <p
                                                className={cnWizardVerticalMenu(
                                                    'ModalText'
                                                )}
                                            >
                                                {t(
                                                    `Подождите, идет скачивание изображений...`
                                                )}
                                            </p>
                                        </div>
                                    </>
                                ) : (
                                    <Button
                                        className={cnWizardVerticalMenu(
                                            cnButton()
                                        )}
                                        type={'button'}
                                        text={t('Скачать все изображения')}
                                        mods={BUTTON_MODE_LIGHT_GREEN}
                                        callBack={() =>
                                            handleDownloadFiles(
                                                stateFiles.map(
                                                    (file) => file.fileUrl
                                                )
                                            )
                                        }
                                    />
                                )}
                            </>
                        )}
                    {isUploading && (
                        <div
                            className={cnWizardVerticalMenu('ModalDescription')}
                        >
                            <p className={cnWizardVerticalMenu('ModalText')}>
                                {t(
                                    `Изоброжения кухни успешно загружены на ваше устройство. Их можно найти в папке "Загрузки" или скачанные файлы вашего браузера (справа от адреса).`
                                )}
                            </p>
                        </div>
                    )}
                </>
            ) : (
                <div className={cnWizardVerticalMenu('ModalDescription')}>
                    <p className={cnWizardVerticalMenu('ModalText')}>
                        {t(
                            `Приносим свои извенения. Выполнить создание изображения в данный момент времени не удалось. 
                                Пожалуйста, повторите попытку позже.`
                        )}
                    </p>
                </div>
            )}
        </div>
    )
}

export default WizardModalDownload
