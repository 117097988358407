import {IContextMenu} from '../interfaces/IContextMenu';
import {ISettingsMenu} from '../interfaces/settingData/ISettingsMenu';
import {IReplaceData} from '../interfaces/IReplaceData';
import {IEditorOptions} from '../interfaces/IEditorOptions';
import {IUnitSpecData} from '../interfaces/IUnitSpecData';

export const RENDER_TYPE_ANIMATION_FRAME = 'requestAnimationFrame';
export const RENDER_TYPE_ANIMATION_LOOP = 'setAnimationLoop';

export const EDITOR_VIEW_TYPE_DEFAULT = 'default';
export const EDITOR_VIEW_TYPE_SMALL_CANVAS = 'smallCanvas';
export const EDITOR_VIEW_TYPE_BIG_CANVAS = 'bigCanvas';

export const TOGGLE_MENU = 'TOGGLE_MENU';
export const TOGGLE_MODAL = 'TOGGLE_MODAL';
export const CHANGE_CONFIG = 'CHANGE_CONFIG';
export const START_SESSION = 'START_SESSION';
export const CLOSE_SESSION = 'CLOSE_SESSION';
export const SET_USER = 'SET_USER';
export const SET_LOCATION = 'SET_LOCATION';
export const SET_LOCATIONS = 'SET_LOCATIONS';
export const SHOW_LOADING = 'SHOW_LOADING';
export const HIDE_LOADING = 'HIDE_LOADING';
export const SEND_SMS_TIMEOUT_SECONDS = 60;

export const KITCHEN_SIZES_TYPE_NONE = 'none'
export const KITCHEN_SIZES_TYPE_ALL = 'all'
export const KITCHEN_VIEW_VISUAL = 'visual'
export const KITCHEN_VIEW_SKETCH = 'sketch'

export const MESSAGE_TYPE_ERROR = 'error';
export const MESSAGE_TYPE_WARNING = 'warning';
export const MESSAGE_TYPE_SUCCESS = 'success';
export const MESSAGE_TYPE_INFO = 'info';

export const ADD_MESSAGE = 'ADD_MESSAGE';
export const REMOVE_MESSAGE = 'REMOVE_MESSAGE';

export const WIZARD_DEFAULT_CONTEXT_MENU: IContextMenu = {
    icons: [],
    position: {x: 0, y: 0},
    visible: false
}
export const WIZARD_DEFAULT_SETTINGS_MENU: ISettingsMenu = {
    visible: false,
}
export const WIZARD_DEFAULT_REPLACE_DATA: IReplaceData = {
    visible: false
}
export const WIZARD_DEFAULT_SPEC_DATA: IUnitSpecData = {
    visible: false
}
export const EDITOR_INIT_OPTIONS: IEditorOptions = {
    canvasClass: 'Editor-Canvas',
    stats: false,
    renderType: RENDER_TYPE_ANIMATION_LOOP,
    viewType: EDITOR_VIEW_TYPE_DEFAULT
};

export const CHANGE_PROJECT_DATA = 'CHANGE_PROJECT_DATA';
export const CHANGE_PROJECT_PRICE_DATA = 'CHANGE_PROJECT_PRICE_DATA';
export const CHANGE_PROJECT_EXTRA_OFFERS_DATA = 'CHANGE_PROJECT_EXTRA_OFFERS_DATA';
export const CHANGE_PROJECT_SERVICES_DATA = 'CHANGE_PROJECT_SERVICES_DATA';
export const CHANGE_ROOM_DATA = 'CHANGE_ROOM_DATA';
export const LOADED_PROJECT = 'LOADED_PROJECT';
export const LOADED_TEXTURES = 'LOADED_TEXTURES';
export const SHOW_CONTEXT_MENU = 'SHOW_CONTEXT_MENU';
export const HIDE_CONTEXT_MENU = 'HIDE_CONTEXT_MENU';
export const SHOW_SETTINGS_MENU = 'SHOW_SETTINGS_MENU';
export const HIDE_SETTINGS_MENU = 'HIDE_SETTINGS_MENU';
export const CHANGE_REPLACE_DATA = 'CHANGE_REPLACE_DATA';
export const CHANGE_UNIT_SPEC = 'CHANGE_UNIT_SPEC';
export const HIDE_REPLACE_DATA = 'HIDE_REPLACE_DATA';
export const HIDE_UNIT_SPEC = 'HIDE_UNIT_SPEC';
export const CHANGE_UNIT_LIST = 'CHANGE_UNIT_LIST';
export const CHANGE_CONSTRUCTIVE_LIST = 'CHANGE_CONSTRUCTIVE_LIST';
export const CHANGE_KITCHEN_VIEW = 'CHANGE_KITCHEN_VIEW';
export const CHANGE_KITCHEN_SIZES_TYPE = 'CHANGE_KITCHEN_SIZES_TYPE';
export const CHANGE_KITCHEN_SHOW_TABLETOPS = 'CHANGE_KITCHEN_SHOW_TABLETOPS';
export const CHANGE_KITCHEN_EURO_ZAPIL = 'CHANGE_KITCHEN_EURO_ZAPIL';
export const CHANGE_KITCHEN_SHOW_APRONS = 'CHANGE_KITCHEN_SHOW_APRONS';
export const CHANGE_KITCHEN_SHOW_CORNERS = 'CHANGE_KITCHEN_SHOW_CORNERS';
export const CHANGE_KITCHEN_SHOW_PLINTHS = 'CHANGE_KITCHEN_SHOW_PLINTHS';
export const CHANGE_KITCHEN_SHOW_LEGS = 'CHANGE_KITCHEN_SHOW_LEGS';
export const CHANGE_KITCHEN_ENABLE_SERVICES = 'CHANGE_KITCHEN_ENABLE_SERVICES';
export const CHANGE_KITCHEN_ENABLE_AUTO_SERVICES = 'CHANGE_KITCHEN_ENABLE_AUTO_SERVICES';
export const CHANGE_KITCHEN_NEED_SAVE = 'CHANGE_KITCHEN_NEED_SAVE';
export const CHANGE_KITCHEN_CHECK_ERRORS_OBJECTS = 'CHANGE_KITCHEN_CHECK_ERRORS_OBJECTS';
export const CHANGE_TECHNOLOG_MAP = 'CHANGE_TECHNOLOG_MAP';
export const CHANGE_TABLETOPS = 'CHANGE_TABLETOPS';
export const CHANGE_SELECT_TABLETOP = 'CHANGE_SELECT_TABLETOP';
export const CHANGE_APRONS = 'CHANGE_APRONS';
export const CHANGE_SELECT_APRON = 'CHANGE_SELECT_APRON';
export const CHANGE_CORNERS = 'CHANGE_CORNERS';
export const CHANGE_INTEGRATED_HANDLES = 'CHANGE_INTEGRATED_HANDLES';
export const CHANGE_SELECT_CORNER = 'CHANGE_SELECT_CORNER';
export const CHANGE_SELECT_INTEGRATED_HANDLE = 'CHANGE_SELECT_INTEGRATED_HANDLE';
export const CHANGE_PLINTHS = 'CHANGE_PLINTHS';
export const CHANGE_SELECT_PLINTH = 'CHANGE_SELECT_PLINTH';
export const CHANGE_FLOOR = 'CHANGE_FLOOR';
export const CHANGE_SELECT_FLOOR = 'CHANGE_SELECT_FLOOR';
export const CHANGE_WALL = 'CHANGE_WALL';
export const CHANGE_SELECT_WALL = 'CHANGE_SELECT_WALL';
export const CHANGE_FACADES = 'CHANGE_FACADES';
export const CHANGE_HANDLES = 'CHANGE_HANDLES';
export const CHANGE_SERVICES = 'CHANGE_SERVICES';
export const CHANGE_KIT_CODES = 'CHANGE_KIT_CODES';
export const CHANGE_SELECT_KIT_CODE = 'CHANGE_SELECT_KIT_CODE';
export const CHANGE_TOP_HANDLE = 'CHANGE_TOP_HANDLE';
export const CHANGE_BOTTOM_HANDLE = 'CHANGE_BOTTOM_HANDLE';
export const CHANGE_CORPUS_MATERIALS = 'CHANGE_CORPUS_MATERIALS';
export const CHANGE_GLASSES = 'CHANGE_GLASSES';
export const CHANGE_MATERIALS = 'CHANGE_MATERIALS';
export const CHANGE_ALL_CORPUS_MATERIALS = 'CHANGE_ALL_CORPUS_MATERIALS';
export const CHANGE_SELECT_CORPUS_MATERIAL = 'CHANGE_SELECT_CORPUS_MATERIAL';
export const CHANGE_SELECT_GLASS = 'CHANGE_SELECT_GLASS';
export const CHANGE_FACADE_MATERIALS = 'CHANGE_FACADE_MATERIALS';
export const CHANGE_ALL_FACADE_MATERIALS = 'CHANGE_ALL_FACADE_MATERIALS';
export const CHANGE_MANUFACTURERS = 'CHANGE_MANUFACTURERS';
export const CHANGE_ALL_CORPUSES = 'CHANGE_ALL_CORPUSES';
export const CHANGE_BOTTOM_FACADE_MATERIAL = 'CHANGE_BOTTOM_FACADE_MATERIAL';
export const CHANGE_TOP_FACADE_MATERIAL = 'CHANGE_TOP_FACADE_MATERIAL';
export const CHANGE_STEPS = 'CHANGE_STEPS';
export const CHANGE_CHECK_STORE = 'CHANGE_CHECK_STORE';
export const CHANGE_ROOM_VISIBLE = 'CHANGE_ROOM_VISIBLE';
export const CHANGE_HINGES_FURNITURE_TYPES = 'CHANGE_HINGES_FURNITURE_TYPES';
export const CHANGE_FACADES_FUNCTION_TYPES = 'CHANGE_FACADES_FUNCTION_TYPES';
export const CHANGE_CATALOG_CODES = 'CHANGE_CATALOG_CODES';
export const CHANGE_EDIT_MODE = 'CHANGE_EDIT_MODE';
export const CHANGE_ITEM_MODULE = 'CHANGE_ITEM_MODULE';
export const CHANGE_MATERIALS_MODE = 'CHANGE_MATERIALS_MODE';
export const CHANGE_ITEM_MATERIAL = 'CHANGE_ITEM_MATERIAL';
export const CHANGE_ITEM_FACADE = "CHANGE_ITEM_FACADE";
export const CHANGE_ITEM_FACADE_MATERIAL = "CHANGE_ITEM_FACADE_MATERIAL";
export const CHANGE_ITEM_PLINTH = "CHANGE_ITEM_PLINTH";
export const CHANGE_ITEM_HANDLE = "CHANGE_ITEM_HANDLE"
export const INIT_EDIT_MATERIAL = 'INIT_EDIT_MATERIAL';
export const INIT_EDIT_MODULE = 'INIT_EDIT_MODULE';

export const SETTING_GROUP_GENERAL = 'general';
export const SETTING_GROUP_CORPUS = 'corpus';
export const SETTING_GROUP_FACADES = 'facades';
export const SETTING_GROUP_APRONS = 'aprons';
export const SETTING_GROUP_CORNERS = 'corners';
export const SETTING_GROUP_PLINTHS = 'plinths';
export const SETTING_GROUP_TABLETOPS = 'tabletops';
export const SETTING_GROUP_LEGS = 'legs';
export const SETTING_GROUP_EQUIPMENTS = 'equipments';
export const SETTING_GROUP_POSITION = 'position';

export const OBJECT_SELECT_TYPE_DEFAULT = 'default'
export const OBJECT_SELECT_TYPE_FACADE = 'facade'
export const OBJECT_SELECT_TYPE_TOP_FACADE_MATERIAL = 'facadeMaterial'
export const OBJECT_SELECT_TYPE_BOTTOM_FACADE_MATERIAL = 'bottomFacadeMaterial'
export const OBJECT_SELECT_TYPE_FACADE_MATERIAL = 'facadeMaterial'
export const OBJECT_SELECT_TYPE_HANDLE = 'handle'
export const OBJECT_SELECT_TYPE_CORPUS_MATERIAL = 'corpusMaterial'
export const OBJECT_SELECT_TYPE_FURNITURE = 'furniture'
export const OBJECT_SELECT_TYPE_TABLETOP = 'tabletop'
export const OBJECT_SELECT_TYPE_APRON = 'apron'
export const OBJECT_SELECT_TYPE_CORNER = 'corner'
export const OBJECT_SELECT_TYPE_PLINTH = 'plinth'

export const OBJECT_SELECT_LIST_TYPE_MODAL = 'modal';
export const OBJECT_SELECT_LIST_TYPE_FLOW = 'flow';

export const DEFAULT_SHOW_MESSAGE_INTERVAL = 5000;

export const DEFAULT_ROOM_LENGTH = 6000;
export const DEFAULT_ROOM_WIDTH = 4000;
export const DEFAULT_ROOM_HEIGHT = 2800;

export const DEFAULT_WALL_DEPTH = 10;

export const SIZE_DEFAULT_TEXT_SIZE = 80;

export const ACTION_DELETE = 'delete';
export const ACTION_SETTINGS = 'settings';
export const ACTION_COPY = 'copy';
export const ACTION_REPLACE = 'replace';
export const ACTION_SHOW_UNIT_SPEC = 'unitSpec';

export const DOOR_FRAME_WIDTH = 70;
export const DOOR_FRAME_DEPTH = 50;
export const WINDOW_FRAME_WIDTH = 50;
export const WINDOW_FRAME_DEPTH = 30;

export const LONG_TOUCH_TIME = 800;


export const PLANE_TYPE_VERTICAL = 'vertical';
export const PLANE_TYPE_HORIZONTAL = 'horizontal';
export const PLANE_TYPE_WALL = 'wall';
export const PLANE_TYPE_FLOOR = 'floor';
export const PLANE_TYPE_ROOF = 'roof';
export const PLANE_TYPE_LEFT_RIGHT = 'leftRight';
export const PLANE_TYPE_FORWARD_BACK = 'forwardBack';

export const SHOW_TEXTURE_LOADING = 'SHOW_TEXTURE_LOADING';
export const HIDE_TEXTURE_LOADING = 'HIDE_TEXTURE_LOADING';

export const LOADING_PRICE = 'LOADING_PRICE';

export const CHANGE_HISTORY_UNDO = 'CHANGE_HISTORY_UNDO';
export const CHANGE_HISTORY_REDO = 'CHANGE_HISTORY_REDO';

export const MESSAGE_ID_CANT_EDIT_PROJECT = 'canChangeProject';

export const CHANGE_WIZARD_INIT_DATA = 'CHANGE_WIZARD_INIT_DATA';

export const AFTER_KEY_MOVE_TIME = 100;

export const AVAILABLE_DOCUMENT_CURSORS = ['ns-resize', 'ew-resize', 'default', 'copy', 'progress', 'wait', 'move'];

export const RENDERER_URL = 'https://render.azbykamebeli.ru/';

